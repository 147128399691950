.Blogs_container {
  margin-top: 25px !important;
  width: 90%;
  margin: auto;
}
.Blogs_title {
  font-family: mb;
  font-size: 54px;
  line-height: 70px;
  color: #000000;
}
.Blogs_icons {
  /* border-right: 1px solid #090909; */
  width: fit-content;
  /* border: none; */
  padding: 0px 15px 0px 3px;
  display: flex;
  align-items: center;
}
.Blog_Details:hover {
  color: #fcc216 !important;
}
.Blogs_iconssmall {
  border-right: 1px solid #ecdddd;
  width: fit-content;
  /* border: none; */
  padding: 0px 15px 0px 3px;
  display: flex;
  align-items: center;
}
.Blogs_icons1 {
  width: fit-content;
  /* border: none; */
  padding: 0px 15px 0px 3px;
  display: flex;
  align-items: center;
}
.navlinks {
  text-decoration: none;
}
.Blogs_icons_text {
  font-family: gm;
  font-size: 14px;
  line-height: 16px;
  color: #090909;
}
.main_img_content {
  font-size: 14px !important;
  font-family: gm !important;
}
.Blogs_icons_text1 {
  font-family: msb !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: black !important;
}
.Blogs_mainIMG {
  /* background-color: red; */
  height: 354px;
  width: 97%;
  background-repeat: no-repeat;
  background-size: cover;
}

.blogs_ads {
  /* background-color: yellow; */

  /* top: 0px;
  right: 76px; */
  position: sticky;
  top: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 343px;
  height: 569px;
}
#root
  > div.border
  > div.Blogs_container
  > div.sc-ifAKCX.bqyzQp
  > div.sc-EHOje.bghjHX {
  margin: 0px 0px !important;
}
.blog_main_container {
  background-color: #f8f8f8;
  border: none !important;
  padding: 1px 0px 40px 0px;
}
.fixed {
  position: fixed;
  top: 0px;
  right: 66px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 343px;
  height: 604px;
}
.sticky {
  background-repeat: no-repeat;
  background-size: contain;
  width: 343px;
  height: 604px;
  position: absolute;
  top: 906px;
  right: 66px;
}
.Blogs_content_container {
  width: 71%;
}
.Blogs_mainIMG_content {
  margin-top: 20px;
  /* background-color: pink; */
  width: 97%;
  font-family: msb;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
}
.blogs_Consistent_Branding {
  font-family: mb;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.blogs_sub1Consistent_Branding {
  font-family: msb;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  width: 97%;
}
.blogs_sub2Consistent_Branding {
  font-family: msb;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
}
.blogs_customer_card {
  background: #f2f2f2;
  border-radius: 2px;
  padding: 15px;
  width: calc(50% - 70px);
}
.blogs_customer_cardTitle {
  font-family: msb;
  font-size: 13px;
  line-height: 15px;
  color: #969696;
}
.blogs_customer_cardsubTitle {
  font-family: msb;
  font-size: 14px;
  line-height: 16px;
  color: #090909;
}
.blogs_card_container {
  display: flex;
}
.Blogs_releted_posttext {
  font-family: msb;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}
.Blogs_releted_posttext_line {
  background: #fcc216;
  width: 12%;
  height: 3px;
  border-radius: 4px;
}
.employe_card {
  background-repeat: no-repeat;
  background-size: cover;
  width: 47%;
  height: 231.11px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
}
.employe_card_container {
  display: flex;
}
.employe_cards_innertext {
  margin-left: 20px;
  font-family: gb;
  font-size: 17.0494px;
  line-height: 25px;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .blogs_card_container {
    display: block;
  }
  .Blogs_content_container {
    width: 100%;
  }
  .blogs_customer_card {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .Blogs_mainIMG {
    height: 139px !important;
    background-repeat: no-repeat;
    background-size: contain !important;
  }
  .Blogs_title {
    font-family: mb;
    font-size: 30px;
    line-height: 45px;
    color: #000000;
  }
  .lcahQU {
    padding-left: 0px !important;
  }
  .employe_card_container {
    display: block;
  }
  .employe_card {
    margin-top: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100% !important;
    height: 231.11px;
  }
}

/* tablet */
@media only screen and (max-width: 990px) {
  .blogs_customer_card {
    width: 90%;
    margin-bottom: 20px;
  }
  .blog_main_container {
    background-color: #f8f8f8;
    border: none !important;
    padding: 1px 0px 40px 0px;
  }
  .Blogs_mainIMG {
    /* background-color: red; */
    height: 354px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .blogs_ads {
    display: none;
  }
  .Blogs_content_container {
    width: 100%;
  }
  .employe_card {
    margin-top: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 70%;
    height: 231.11px;
  }
  .Blogs_mainIMG {
    margin: 0px !important;
  }
}
