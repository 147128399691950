.Youtubemobileslider,
.Targetsalesmobile {
  display: none;
}

.home_container {
  background-color: #f8f8f8;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 10px;
}
.Up_Menu_Arrow {
  position: absolute;
  bottom: 59px;
  z-index: 1;
  right: 23px;
  cursor: pointer;
}
.rights {
  font-family: mm;
  font-size: 13px;
  line-height: 16.41px;
  color: #909090;
  margin-bottom: 0px;
}
.Version {
  font-family: mm;
  font-size: 14px;
  line-height: 16px;
  color: #909090;
}
.backButton{
  display: flex;
  align-items: center;
  padding:20px 0px 0px 56px;
  color: white;
  cursor: pointer;      
     
 }


.over_flow_container {
  overflow-x: scroll;
}

.YoutubeVideos_container {
  /* overflow-x: scroll; */
  display: flex;
  margin-top: 35px;
  margin-left: 76px;
  width: 93%;
  padding: 5px;
}

.Youtubewebslider {
  display: block;
}

.card-img-top {
  width: 100%;
  height: 128px;
}
.card-img-top1 {
  width: 100% !important;
  height: 182px !important;
}

.YoutubeVideo {
  margin-right: 60px;
  border-radius: 10px;
  width: 302px;
  height: 182px;
}

.carousel-indicators .active {
  width: 30px !important;
}

/* .carousel-indicators li {
  width: 3px;
} */

/* TargetSales */
.TargetSales {
  background-color: #ffffff;
  width: 86%;
  height: 125px;
  margin-top: 40px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cendrolproject_container {
  position: relative;
  margin: auto;
  width: 86%;
  margin-top: 40px;
}

.targetsalesLeft_target {
  font-family: mb;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;

  color: #000000;
}

.targetsalescenter_time {
  padding: 10px 10px;
  background-color: #fffbed;
  display: flex;
  justify-content: space-between;
}

.Time {
  font-family: msb;
  font-style: normal;
  font-weight: 400;
  font-size: 29.0967px;
  line-height: 36px;
  text-align: center;
  color: #fcc314;
}

.Time_title {
  font-family: msb;
  font-style: normal;
  font-weight: 400;
  font-size: 14.5483px;
  line-height: 18px;
  color: #221e1f;
}

.Time_dot {
  width: 3.45px;
  height: 11.49px;
  color: gray;
}

.targetsalesLeft_time {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #b9b9b9;
}

.targetsalesright {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.targetsalesright_title {
  font-family: msb;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: right;
  color: #221e1f;
}

.targetsalesLeft_days {
  color: red;
  text-decoration: none;
}

.targetsalesright_progress {
  width: 70px;
  height: 70px;
}

.cendrolproject {
  /* margin-left: 39px; */

  font-family: mb;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;

  color: #000000;
}

.cendrolproject_buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bghjHX {
  margin: 0px !important;
}

.cendrolproject_butoon {
  padding: 12px 26px 10px 22px;
  margin-right: 25px;
  border: none;
  background: #ffffff;
  border-radius: 3px;
  font-family: msb;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #1a1a1a;
}

.cendrolproject_butoon:hover {
  cursor: pointer;
  /* background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%); */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.cendrolproject_active {
  background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%);
}

.cendrolproject_arrow_button {
  display: flex;
  margin-right: 43px;
}
.Sales {
  font-family: msb;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #969696;
  margin-top: 26px;
}

.cendrolproject_right {
  cursor: pointer;
  padding: 3px 6px 4px 6px;
  background: #ffffff !important;
  border-radius: 19px;
}

.youtube_carousel {
  height: 400px !important;
}

.cendrolproject_right:hover {
  background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%) !important;
}

.youtube_Title {
  font-family: msb;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
}

.control-prev {
  color: black !important;
}

.carousel-control-prev,
.carousel-control-next {
  position: relative !important;
  color: black !important;
}
.hide_slider {
  display: none !important;
}

.cendrolproject_grid {
  width: 100%;
  margin-top: 20px;
}

.TrendingBlogs,
.CustomersSaying {
  margin-left: 3%;
  width: 94%;
}

.CendrolCollections {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TrendingPartners {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TrendingBlogs,
.CustomersSaying {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TrendingBlog_title {
  font-family: mb;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;

  color: #000000;
}

.TrendingPartners_title,
.CustomersSaying_title {
  font-family: mb !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 28px !important;

  color: #000000 !important;
}

.CendrolCollections_title {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;

  color: #000000;
}

.TrendingBlogs_arrow_button,
.CustomersSaying {
  display: flex;
}

.TrendingPartners_arrow_button {
  display: flex;
}

.CendrolCollections_arrow_button,
.CustomersSaying_arrow_button {
  display: flex;
}

.TrendingBlogs_right,
.CustomersSaying_right {
  cursor: pointer;
  padding: 3px 6px 4px 6px;
  background: #ffffff !important;
  border-radius: 19px;
}

.TrendingPartners_right {
  cursor: pointer;
  padding: 3px 6px 4px 6px;
  background: #ffffff !important;
  border-radius: 19px;
}

.TrendingPartners_right:hover,
.CendrolCollections_right:hover,
.TrendingBlogs_right:hover,
.CustomersSaying_right:hover {
  background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%) !important;
}

.CendrolCollections_right {
  cursor: pointer;
  padding: 3px 6px 6px 6px;
  background: #ffffff !important;
  border-radius: 19px;
}

.cendrolCollectionmobile_slider,
.TrendingPartnersmobile_slider,
.TrendingBlogsmobile_slider,
.CustomersSaying_mobile {
  display: none;
}

.youtube_container {
  position: relative;
  margin: auto;
  width: 86%;
  margin-top: 40px;
}

.CendrolCollections {
  margin-left: 3%;
  width: 94%;
}

.cendrolCollection_slider {
  margin-left: 1%;
  width: 99%;
}

.TrendingBlogs_slider {
  margin-left: 1%;
  width: 99%;
}

.TrendingPartners_slider {
  width: 100%;
}

.TrendingPartners {
  margin-left: 3%;
  width: 94%;
}

.Targetsalestablet {
  display: none;
}

.youtube_container_imge {
  height: 182px;
  width: 100%;
  border-radius: 6px;
}

.TrendingPartners_slider_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 324px !important;ssss */
  /* height: 107px; */
  background: #ffffff;
  border-radius: 4px;
}

.TrendingPartners_card_title {
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.TrendingPartners_card_subtitle {
  font-family: gm;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #969696;
}

.TrendingPartners_card_story {
  font-family: msb;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

.cendrolproject_badges {
  float: right;
  height: 22.45px;
  border-radius: 2.49465px;
  margin: 21px 22px;
  width: 47.9px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 11.8358px;
  line-height: 14px;
}
.redpopup_navigation_activetext {
  font-family: msb;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  cursor: pointer;
  margin-bottom: 2px;
  text-decoration: underline;
}

.cendrolprojrctImg_content {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: red; */
  width: 100%;
  padding: 21px;
  padding-top: 89px;
}

.cendrolprojectImg_title {
  font-family: mb !important;
  font-weight: 600 !important;
  font-size: 19.2692px !important;
  line-height: 25px !important;
  color: #221e1f !important;
}

.cendrolprojectImg_titlesub {
  font-family: gm;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  width: 100% !important;
  background: white;
}

.card-title {
  font-family: gb;
  font-style: normal;
  font-weight: 400;
  font-size: 16.884px;
  line-height: 21px;
  color: #fcc314;
}

.card-text {
  font-family: gb;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #1a1a1a;
}
.youtube-subs {
  margin-bottom: 0px;
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #fcc216;
}
.cendrolproject_view_namecontainer {
  width: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.share_font {
  font-family: gsb;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1a1a1a;
}

.card {
  width: 100% !important;
  border: none !important;
}

.cendrol_card_content1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cendroSqft {
  font-family: gm;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.cendrolProjectView_container {
  width: 691px;
  height: 639px;
  background: #ffffff;
  border-radius: 6.20948px;
}
.arrow_position {
  position: relative;
  top: 219px;
}

.cendrolProjectView_title {
  width: fit-content;
  height: 25px;
  font-family: mb;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin: 14px 2px 7px 14px;
}

.cendrolProject_Closebtn {
  cursor: pointer;
  margin: 6px;
  float: right;
}
.youtubearrowright {
  position: absolute !important;
  right: -22px;
  top: 76px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}
.youtubearrowleft {
  position: absolute !important;
  top: 72px !important;
  left: -22px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.cendrolProjectView_blur_content {
  width: 650px;
  height: 73px;
  padding-top: 13px;
  background: #f7f7f7;
  border-radius: 2px;
}
.cendrolproject_leftarrow {
  position: absolute !important;
  background-color: #fff !important;
  right: 47px;
  top: -73px;
}
.cendrolproject_leftarrow:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.cendrolproject_rightarrow {
  position: absolute !important;
  right: 0px;
  top: -73px;
  background-color: #fff !important;
}
.cendrolproject_rightarrow:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}
.blogs_rightarrow {
  z-index: 1 !important;
  right: 0px;
  position: absolute !important;
  background-color: #fff !important;
  top: -57px;
}
.link_tags {
  text-decoration: none !important;
  color: #f6ba00 !important;
}
.navlink {
  text-decoration: none !important;
  color: #0e0e0e !important;
}

.cendrolProjectView_blur_content_name {
  margin: 17px;
  width: 71.89px;
  font-family: msb;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.cendrolProject_sharebtn {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 9px 5px 14px;
  background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%) !important;
  border: none;
}
.Share {
  font-family: gsb;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  color: #1a1a1a;
}

.cendrolProject_blur_subtitle {
  width: 68.89px;
  height: 19px;
  font-family: msb;
  font-size: 15px;
  line-height: 18px;
  color: #fdc315;
}

.cendrolProject_gap {
  border: 1px solid #d6d6d6;
  height: 44px;
}

.cendrolProject_blur_title {
  font-family: msb;
  font-size: 13px;
  line-height: 15px;
  color: #969696;
}

.CustomersSaying {
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 19.7807px;
  line-height: 29px;
  color: #000000;
}
.TrendingBlogs_title {
  font-family: mb !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 19px !important;
  line-height: 24px !important;
  margin-bottom: 1em !important;
  color: #000000 !important;
}

.TrendingBlogs_subtitle {
  font-family: msb !important;
  font-size: 13px !important;
  line-height: 19px !important;
  color: #969696 !important;
  margin-bottom: 1em !important;
}

.border_right {
  padding-right: 10px;
}
.likes_container {
  width: 100%;
  display: flex;
  margin-top: 10px;
}
.likes_comment {
  font-family: msb;
  font-size: 12px;
  line-height: 14px;

  /* Black */

  color: #0e0e0e;
}
/* CustomersSaying */
.CustomersSayingcard_name {
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1a1a1a;
}

.CustomersSayingcard_loc {
  font-family: gm;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #969696;
}

.CustomersSayingcard_text {
  font-family: gm;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 180%;
  color: #1a1a1a;
}

.CendrolProjectShare {
  width: 29%;
  height: 185px;
  background: #ffffff;
  border-radius: 6.20948px;
}
.Share_items {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.cendrolproject_Serchbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 89%;
  height: 54px;
  left: 544px;
  top: 147px;
  background: #f7f7f7;
  border-radius: 4px;
}
.serch_inputtag {
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  color: #969696;
  border: none;
  fill: #f7f7f7;
  outline: none;
  background: #f7f7f7;
  padding: 5px;
}
.cendrolproject_searchresult {
  border-bottom: 1px solid rgba(150, 150, 150, 0.1);
  display: flex;
  justify-content: start;
  align-items: center;
  width: 351px;
  height: 76.01px;
}
.serch_resultname {
  font-family: gsb;
  font-size: 13.0427px;
  line-height: 15px;
  color: #0e0e0e;
}
.search_resultstage {
  font-family: gm;
  font-size: 12.1111px;
  line-height: 14px;
  color: #969696;
}
.share_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 359px;
  height: 46px;
  border: none;
  background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%) !important;
}
.cendrolproject_scroll {
  width: 351px;
  height: 247.01px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.share_project {
  font-family: gsb;
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
}
.share {
  display: flex;
  justify-content: center;
  align-items: center;
}

.targetpartners_rightarrow {
  position: absolute !important;
  background-color: #fff !important;
  right: 0px;
  top: -53px;
}
.customer_rightarrow {
  z-index: 1 !important;
  position: relative !important;
  float: right;
  top: -194px;
  right: 23px;
}
.footer {
  /* margin-top: 10%; */
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f6;
  position: relative;
}

.rights {
  font-family: mm;
  font-size: 13px;
  line-height: 16.41px;
  color: #909090;
}

.customer_leftarrow {
  z-index: 1 !important;
  position: relative !important;
  float: right;
  top: -68px;
  right: 74px;
}

.cendrolcollection_leftarrow,
.targetpartners_leftarrow {
  z-index: 1 !important;
  position: absolute !important;
  right: 49px;
  top: -54px;

  background-color: #fff !important;
}
.cendrolcollection_rightarrow {
  z-index: 1 !important;
  position: relative;
  float: right;
  top: -362px;
  right: 45px;
}
.blogs_leftarrow {
  z-index: 1 !important;
  position: absolute !important;
  background-color: #fff !important;
  right: 51px;
  top: -57px;
}
.cendrolcollection_leftarrow:hover,
.cendrolcollection_rightarrow:hover,
.targetpartners_leftarrow:hover,
.targetpartners_rightarrow:hover,
.blogs_leftarrow:hover,
.blogs_rightarrow:hover,
.customer_leftarrow:hover,
.customer_rightarrow:hover {
  background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%) !important;
}
.view_more {
  font-family: msb;
  font-size: 13px;
  line-height: 15px;
  color: #fcc314;
  margin-left: 10px;
  cursor: pointer;
}
.cendrolproject_location {
  display: flex;
  align-items: center;
}
.cendrolproject_location_text {
  font-family: msb;
  font-size: 11.7849px;
  line-height: 14px;
  color: #969696;
}
.cendrolproject_sqft_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cendrolproject_sqft {
  margin-top: 10px;
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border-radius: 1.07135px;
  padding: 11px 10px 11px 9px;
}
.cendrolproject__sqft_text {
  font-family: mm;
  font-size: 11px;
  line-height: 14px;
  color: #000000;
}

.card-img-overlay {
  /* position: absolute;
  top: 156px !important;
  right: 0;
  bottom: 0;
  left: 0; */
  display: flex !important;
  flex-direction: column !important;
  justify-content: end !important;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}
.cendrolcollecton__title {
  font-family: "Lora";
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
}
.cendrolcollecton__subtitle {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.cendrolcollectionblur_card_container {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5.68281px);
  border-radius: 2px;
}
.blur_block {
  padding: 10px;
}
.bulr_border {
  border-right: 1px solid #ffffff;
}
.blur_sqft {
  font-family: gm;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.blur_subsqft {
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #fcc314;
}
#navbar_sm {
  display: block;
}
.partnerCard {
  width: 100%;
  height: fit-content;
  background: #ffffff;
  border-radius: 1px;
  padding: 17px;
}
.partnerCard_avtar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
}
.partner_text {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #919191;
  height: 167px;
  padding-top: 10px;
}
.partner_btn {
  background: #fff9e5;
  border-radius: 1px;
  text-align: center;
  padding: 6px 0px 6px 0px;
}
.partner_loc {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #969696;
}

.partner_name {
  font-family: mb;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.video_close {
  background-color: #e9d59b;
  width: 80%;
  height: 80%;
  padding: 3px;
  border-radius: 5px;
}

.fab_btn {
  margin-top: 14px !important;
}
.video_container {
  float: right;

  /* background-color: transparent; */
}
.video_container_imge {
  height: 100%;
  width: 100%;
  border-radius: 6px;
}
#root > div.sc-ifAKCX.bqyzQp > div.sc-EHOje.bghjHX {
  margin: 0px 0px !important;
  width: 100%;
}
.blogs_left_arrow_btn {
  color: #000000 !important;
  position: absolute !important;
  top: calc(84% - 17.5px) !important;
  height: 41px !important;
  width: 104px !important;
  background: #ffffff !important;
  /* backdrop-filter: blur(24px) !important; */
  border-radius: 2px !important;
  box-shadow: 0 0 5px 0 #0009 !important;
  z-index: 10 !important;
  cursor: pointer !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  /* opacity: 0.6 !important; */
  /* transition: opacity 0.25s !important; */
  left: initial !important;
  right: 143px !important;
}
.blogs_right_arrow_btn {
  position: absolute !important;
  top: calc(84% - 17.5px) !important;
  height: 41px !important;
  width: 104px !important;
  color: #000000 !important;

  background: #ffffff !important;
  /* backdrop-filter: blur(24px) !important; */
  border-radius: 2px !important;
  box-shadow: 0 0 5px 0 #0009 !important;
  z-index: 10 !important;
  cursor: pointer !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  /* opacity: 0.6 !important;
  transition: opacity 0.25s !important; */
  left: initial !important;
  right: 24px !important;
}
#root > div.sc-ifAKCX.bqyzQp > div:nth-child(1) > button {
  color: white !important;
}
#root > div.sc-ifAKCX.bqyzQp > div:nth-child(3) > button {
  color: white !important;
}
#root
  > div.sc-ifAKCX.bqyzQp
  > div:nth-child(1)
  > button
  > span.MuiButton-startIcon.MuiButton-iconSizeMedium.css-1d6wzja-MuiButton-startIcon
  > svg,
#root
  > div.sc-ifAKCX.bqyzQp
  > div:nth-child(3)
  > button
  > span.MuiButton-endIcon.MuiButton-iconSizeMedium.css-9tj150-MuiButton-endIcon
  > svg {
  font-size: 15px !important;
  color: #fcc314 !important;
}

/* .blogs_left_arrow_btn {
  top: calc(89% - 17.5px) !important;
  left: initial !important;
  right: 48px !important;
} */

.blog_carousel {
  width: fit-content;
  position: relative;
  top: 21%;
  left: 5%;
}
.Read_Full_Blog {
  background: linear-gradient(
    93.54deg,
    #ffe185 -4.9%,
    #f7ba00 106.63%
  ) !important;
  border-radius: 2px !important;
  font-family: msb !important;
  font-size: 13px !important;
  line-height: 15px !important;
  color: #0e0e0e !important;
  text-transform: none !important;
  margin-top: 10px !important;
  margin-top: 17px !important;
  padding: 10px !important;
  width: 50% !important;
}
.blog_carousel_subtext {
  color: #ffffff !important;
}
.cendrolProjectView_imgmain {
  width: 96%;
  height: 287px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 1px 12px 0px 14px;
}
.Project_Description {
  width: 96%;
  margin: 3px 0px 0px 17px;
}
.Project_Description_text {
  font-family: mb;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.Project_Description_subtext {
  font-family: msb;
  font-size: 15px;
  line-height: 26px;
  color: #969696;
}
.cendrolProjectView_subimgmain {
  width: 96%;
  height: 10%;
  margin: 1px 12px 0px 14px;
}
.images_cendrolproject {
  background-color: #f2f2f2;
  padding: 15px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_border {
  border: 2px solid #fcc216;
}
.cendrolProjectView_images {
  width: 75px;
  height: 41px;
  object-fit: cover;
  margin-right: 20px;
}
.blogs_content_container {
  width: 90%;
  margin: 10px auto;
  margin-bottom: 50px !important;
}
.Recent_Articles_txt {
  margin-bottom: 24px;
  margin-top: 34px;
  font-family: mb;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}
#root > div.blogs_content_container > div > div.sc-EHOje.bghjHX {
  margin: 0px 0px !important;
}
.blog_carousel_text {
  font-family: mb;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 62px;
  color: #ffffff;
  width: fit-content;
}
.TrandingBlogsView_blogscontainer {
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: 400px;
}

.collection_card_mains {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 50%;
  height: 310px;
}
/* .blogs_leftarrow {
  position: absolute;
} */
.css-12m9wit-MuiButtonBase-root-MuiFab-root {
  z-index: 20 !important;
}
.CendrolProjectViewAll_container {
  width: 100%;
  height: fit-content;
  background-color: #f8f8f8;
}
.CendrolProjectViewAll {
  width: 86%;
  margin: auto;
}

.cendrolProjectparent {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.likes_subcontainer {
  display: flex;
  align-items: center;
  margin-right: 22px;
}
.likes_subcontainersingle {
  display: flex;
  align-items: center;
}
#blogs_pc {
  display: block;
}
.Blog_Details {
  display: none !important;
  position: fixed !important;
  font-family: msb !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-decoration: none !important;
  color: #969696 !important;
  top: 20px !important;
  left: 134px !important;
}
.Return_Blogs {
  font-family: msb;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.mobile_nav {
  display: flex;
  align-items: center;
}
/* .cendrolProjectparent1 {
  grid-area: 1 / 1 / 2 / 2;
}
.cendrolProjectparent2 {
  grid-area: 1 / 2 / 2 / 3;
}
.cendrolProjectparent3 {
  grid-area: 1 / 3 / 2 / 4;
}
.cendrolProjectparent4 {
  grid-area: 1 / 4 / 2 / 5;
} */
/* #root
  > div.home_container
  > div.cendrolproject_container.my-4.homebody
  > div.cendrolproject_grid.ms-3
  > div
  > div.sc-EHOje.bghjHX
  > div
  > div
  > div {
  display: none;
} */

/*  */
@media only screen and (max-width: 600px) {
  .Targetsalestablet {
    display: none !important;
  }
  .Blog_Details,
  .mobile_nav {
    display: none;
  }
  .backButton{
    display: none;
  }
  .blog_main_container {
    background-color: #f8f8f8;
    border: none !important;

    padding: 1px 0px 40px 0px;
  }

  .iStYvh {
    padding-left: 0px !important;
  }
  .blog_carousel_text {
    font-family: mb;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 62px;
    width: 50%;
    color: #ffffff;
    width: -moz-fit-content;
    width: fit-content;
  }
  .TrandingBlogsView_blogscontainer {
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    height: 233px;
  }
  .heaaOs {
    margin-top: 10px;
  }

  #navbar_sm {
    display: none;
  }
  .likes_comment {
    font-family: msb;
    font-size: 13px;
    line-height: 14px;
    color: #0e0e0e;
  }
  #root
    > div.border.blog_main_container
    > div.Blogs_container
    > div.sc-ifAKCX.bqyzQp
    > div.sc-EHOje.bghjHX
    > div {
    padding-left: 0px;
  }
  .TrendingPartners_title,
  .CustomersSaying_title {
    margin-top: 31px;
    font-family: gb !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 22px !important;
    line-height: 27px !important;
    color: #000000;
  }

  .youtube-subs {
    margin-bottom: 10px;
    font-family: gm;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #fcc216;
  }
  .card-img-overlay {
    position: absolute;
    top: 121px !important;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(0.25rem - 1px);
  }
  .cendrolproject_butoon {
    /* padding: 5px 10px 5px 15px; */
    margin-right: 16px;
    border: none;
    background: #ffffff;
    border-radius: 3px;
    font-family: gsb;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  .TrendingPartners_title,
  .CustomersSaying_title,
  .TrendingBlog_title,
  .cendrolproject,
  .CendrolCollections_title {
    font-family: gb !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #000000;
  }
  .cendrolproject {
    margin-left: 39px;
    font-family: gb;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
  }
  .cendrolProjectView_container {
    width: 96%;
    height: 52%;
  }
  .TrendingPartners_slider {
    width: 100%;
  }
  .qFTwe {
    padding-left: 0px;
  }
  .iDVovp {
    padding-left: 0px;
  }
  .card {
    width: 100% !important;
    border: none !important;
  }
  .cendrolproject_view_namecontainer {
    width: 83%;
    margin-left: 28px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CendrolProjectShare {
    width: 97%;
    height: 193px;
    background: #ffffff;
    border-radius: 6.20948px;
  }
  .cendrolProjectView_blur_content {
    position: relative;
    bottom: 5px;
    width: 84%;
    height: 72px;
    background-color: #f7f7f7;
    border: 1px solid #ffffff;
    border-radius: 4px;
  }
  .cendrolProjectView_blur_content_name {
    margin: 10px;
    width: 71.89px;
    height: 27px;
    font-family: gb;
    font-size: 15px;
    line-height: 26px;
  }
  .cendrolProject_blur_title {
    width: 81.87px;
    height: 16px;
    font-family: gsb;
    font-size: 10px;
    line-height: 15px;
  }
  .cendrolProject_blur_subtitle {
    width: 68.89px;
    height: 19px;
    font-family: gb;
    font-size: 11px;
    line-height: 18px;
    color: #fdc315;
  }
  .share_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91%;
    height: 46px;
    border: none;
    background: -webkit-linear-gradient(
      top,
      #ffe185 1%,
      #f6ba00 100%
    ) !important;
  }

  .youtube_Title {
    font-size: 18px;
    font-family: gsb;
    margin-top: -7px;
    /* margin-left: 32px; */
  }

  .Youtubewebslider {
    display: none;
  }
  /* .cendrolproject_buttons {
    margin-top: 10px !important;
    display: none;
  } */
  .cendrolproject_buttons {
    margin-left: 13px;
  }

  .youtube_container {
    padding-top: 20px;
    margin: 0px !important ;
    width: 100%;
  }

  .cendrolprojecmobile {
    margin-left: 13px;
    margin-bottom: 14px;
  }

  .CendrolCollections {
    margin-left: 13px;
  }

  .TrendingPartner {
    margin-left: 10%;
  }

  .TrendingPartners_slider_card {
    background: #ffffff;
    border-radius: 4px;
  }

  .youtubemobileslider_card {
    height: 182px;
    width: 302px;
    border-radius: 6px;
    margin-left: 31px;
    margin-top: 31px;
  }

  .Youtubemobileslider,
  .cendrolCollectionmobile_slider,
  .TrendingPartnersmobile_slider,
  .TrendingBlogsmobile_slider,
  .CustomersSaying_mobile {
    display: block;
  }

  .cendrolproject_button_mobile:hover {
    background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%);
  }

  .cendrolproject_active {
    background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%);
  }

  .carousel-inner img {
    height: 68% !important;
    width: 118% !important;
    padding: 30px 102px 3px 35px;
  }

  /* tagetsales */
  .TargetSales {
    display: none;
  }

  .cendrolproject_mobile {
    display: block;
  }

  .cendrolproject_button_mobile {
    font-family: gsb;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #221e1f;
    border: none;
    background: white;
  }

  .Targetsalesmobile {
    display: block;
    height: 165px;
    background: #fffbed;
    border-radius: 5px;
  }
  .css-1ri6ub7-MuiPaper-root-MuiCard-root {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.87) !important;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border-radius: 4px !important;
    box-shadow: 0px 2px 1px -1pxrgba (0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
    overflow: hidden !important;
    max-width: 366px !important;
  }
  .rsxxr {
    padding-left: 0px !important;
    grid-template-columns: repeat(7, 90%) !important;
    grid-column-gap: calc(0% + 20px) !important;
    -webkit-transform: translateX(0) !important;
    -ms-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
  .targetsalesLeft_target {
    font-family: gsb;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .targetsalesLeft {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .targetsalesLeft_time {
    font-family: gm;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #969696;
  }

  .targetsalescenter_time {
    width: 221px;
    height: 56px;
    background: #ffffff;
    border-radius: 2.25627px;
  }

  .Time {
    font-family: gsb;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }

  .Time_title {
    font-family: gm;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #221e1f;
  }

  .targetComplet {
    font-family: gsb;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #221e1f;
  }
  .footer {
    display: none;

  }
  /* .rights {
    margin-top: 10px;
    margin-bottom: 10px;
  } */

  .homebody {
    background-color: #f8f8f8;
  }
}

@media only screen and (max-width: 990px) {
  .TargetSales {
    display: none;
  }

  .Targetsalestablet {
    width: 86%;
    display: flex;
    justify-content: space-between;
  }
}
